/* DocumentSignPage.css */
.document-sign-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .pdf-container {
    position: relative;
    width: 80%;
    margin: 20px 0;
    border: 1px solid #ddd;
  }
  
  .pdf-canvas {
    width: 100%;
    height: auto;
    display: block;
  }
  
  .signature-canvas-overlay {
    background: transparent;
    cursor: crosshair;
  }
  
  .signature-buttons {
    margin-top: 10px;
  }
  
  .signature-buttons button {
    margin: 5px;
    padding: 8px 15px;
    border: none;
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    font-size: 16px;
  }
  
  .signature-buttons button:hover {
    background-color: #45a049;
  }
  
  .pdf-preview {
    margin-top: 20px;
    text-align: center;
  }
  
  .pdf-preview h3 {
    margin-bottom: 10px;
  }
  